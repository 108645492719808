/* .tvlChainFilter {
  background: white;
  padding: 5px;
  border-radius: 20px;
  width: 32px;
  margin-right: 8px;
} */

/* chain dropdown style start */
:root {
  --datatable-row-background: white;
  --datatable-row-color: black;
}
[data-theme="dark"] {
  --datatable-row-background: #121212;
  --datatable-row-color: white;
}
section.radioSection {
  display: flex;
  flex-flow: row wrap;
}
section.radioSection > div.radioSectionChild {
  /* flex: 1; */
  padding: 0.5rem;
  width: 200px;
}
input[type="radio"] {
  display: none;
  &:not(:disabled) ~ label {
    cursor: pointer;
  }
  &:disabled ~ label {
    color: hsla(150, 5%, 75%, 1);
    border-color: hsla(150, 5%, 75%, 1);
    box-shadow: none;
    cursor: not-allowed;
  }
}

@media only screen and (max-width: 700px) {
  section.radioSection {
    flex-direction: column;
  }
}

/* chain dropdown style end */

.p-selectbutton .p-button.p-highlight {
  background: #914de5;
  border-color: #914de5;
  border-radius: 0;
}
.p-buttonset .p-button {
  border-radius: 0;
}

.lqdt_icon_wdth {
  background: white;
  padding: 4px;
  border-radius: 50%;
}
.lqdt_icon_wdth_key {
  background: white;
  padding: 5px 8px;
  border-radius: 50%;
}

/* .mr_5 {
  margin-right: 3rem;
} */
/* .ml_5 {
  margin-left: 5rem;
} */

.mb_20 {
  margin-bottom: 20px;
}

.secondary_color {
  color: var(--seconday_color);
}

.p-accordion .p-accordion-content {
  border: 1px solid var(--border_color);
  background: var(--body_background);
  color: var(--body_color);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid var(--border_color);
  color: var(--header_text_color);
  background: var(--header_background);
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link {
  border: 1px solid var(--border_color);
  color: var(--header_text_color);
  background: var(--header_background);
}

/* .redHatFont {
  font-family: RedHat;
} */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.second_section_back {
  border: 1px solid gray;
  padding: 20px;
  width: 46%;
  margin: 10px;
}

.mr_20 {
  margin-right: 20px;
}

.backGrd {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
  linear-gradient(0deg, rgba(40, 91, 221, 0.04), rgba(40, 91, 221, 0.04)); */
  background: rgba(40, 91, 221, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.02);
  padding: 12px 14px;
}
/* .dsp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
} */

.dsp_flex_start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* .dsp_start {
  display: flex;
  justify-content: space-between;
  align-items: start;
} */

.card_snd_clr {
  color: #544e4e;
}

.wdth_70 {
  width: 70%;
}
.wdth_10 {
  width: 20rem;
}

.OvervwWdth {
  width: 70%;
}

/* .outer_section {
  border: 2px solid var(--border_color);
  padding: 15px;
  margin: 10px;
} */

/* .outer_section_detail {
  border: 1px solid var(--border_color);
  padding: 15px;
  margin: 10px;
} */

.btc_img_width {
  margin-right: 10px;
  width: 35px;
}

.wdth_140 {
  width: 140px;
}

.wdth_30_px {
  width: 30px;
}

.btc_asst_img_width {
  width: 35px;
  margin-right: -10px;
  border-radius: 50%;
}

.mr_20 {
  margin-right: 20px;
}

.mr_mob_20 {
  margin-right: 20px;
}

.header_font_size {
  font-size: 22px;
  font-weight: 600;
  font-family: RedHat;
}
.wthlist_back {
  background: rgba(99, 99, 99, 0.15);
  margin: 10px 5px;
  padding: 10px 15px;
  font-size: 14px;
}
.wthlist_back_img {
  margin-right: 5px;
  margin-top: -4px;
}
.kyc_back {
  margin: 10px 2px;
  padding: 10px 15px;
  font-size: 14px;
  background: rgba(153, 207, 132, 0.28);
  border-radius: 15px;
  color: #63a748;
}
.trdng_width {
  padding: 6px 10px 7px 10px;
  border: 0.672px solid #9f78c9;
  background: rgba(149, 67, 228, 0.14);
}
.trdnImgMargn {
  margin-right: 5px;
}
.trdng_outer {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-right: 20px;
  /* color: #45822d; */
}
.fnt_wgt_600 {
  font-weight: 600;
}

.wdth_130 {
  width: 130px;
}

.mr_35 {
  margin-right: 35px;
}

/* .exchng_mrgn {
  margin: 20px 10px 40px 5px;
} */

.mr_10 {
  margin-right: 10px;
}

.hdr_txt {
  font-size: 20px;
  color: rgba(145, 77, 229, 1);
  font-weight: 600;
  font-family: RedHat;
}

.text_center_sreen {
  position: absolute;
  top: 50%;
  left: 50%;
}

.font_18 {
  font-size: 18px;
}
.font_181 {
  font-size: 18px;

  margin-bottom: 0.5rem;
}
.mrgn_btm_15 {
  margin-bottom: 15px;
}
.westBtn {
  border: 1px solid var(--border_color);
  padding: 6px 12px 6px;
  margin-right: 7px;
  color: #9543e4;
}

.table > :not(caption) > * > * {
  color: var(--body_color);
}

.prtfol_back {
  border: 1px solid rgba(0, 0, 0, 0.18);
  padding: 10px;
}
.holding_header {
  /* font-weight: 600; */
  font-size: 18px;
}

.holding_header_inner {
  /* color: #914de5; */
  font-weight: 800;
  font-size: 24px;
}

.op_60 {
  opacity: 0.6;
}

.holding_val {
  color: #63a748;
  font-weight: 600;
}
.btn-riv-primary {
  background: rgba(145, 77, 229, 1);
  color: white;
  border-radius: 0;
  min-width: 130px;
  box-shadow: 5px 5px 0px var(--connect_btn_clr);
  transition: all ease 0.5s;
  font-size: 14px;
  padding: 10px 28px;
  border: 1px solid black;
}

.btn-riv-primary:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

.btn-riv-secondary {
  border: 1px solid var(--connect_btn_clr);
  color: var(--body_color);
  border-radius: 0;
  min-width: 130px;
}

.p-progressbar {
  height: 1rem;
  background: var(--progress_bar_clr);
}
.p-progressbar .p-progressbar-value {
  background: rgba(145, 77, 229, 1);
}
.wdth_30 {
  width: 30px;
}
.buy_cake {
  font-weight: 600;
  /* font-size: 18px; */
  color: #914de5;
  text-decoration: underline;
}
/* .wdth_100 {
  width: 100%;
} */
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border: 1px solid transparent;
  color: rgba(145, 77, 229, 1);
  border-radius: 0;
  background: transparent;
  filter: none;
}

/* .p-tabview .p-tabview-nav li {
    border: none;
  } */

/* .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: 1px solid;
  } */
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  height: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px 20px;
  font-weight: 500;
  margin: 0;
  border: 1px solid var(--border_color);
  border-radius: 0;
  filter: grayscale(1);
  background: var(--body_background);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li {
  border: none;
}

.p-tabview .p-tabview-nav {
  background: var(--body_background);
}

.p-tabview .p-tabview-nav {
  border: none;
}
.p-tabview .p-tabview-panels {
  padding: 0;
  background: var(--body_background);
  color: var(--body_color);
}

.wdth_46 {
  width: 46%;
}

.dsp_around {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.items-center {
  align-items: center;
}
.venusWdth {
  width: 30px;
  margin-right: 5px;
}

.pancakeWdth {
  width: 32px;
}

.secondary_color {
  color: #914de5;
}
/* .card_wdth_50 {
  width: 50%;
} */
.bckgrnd_img_card_fnt {
  color: black;
}
.wdth_45 {
  width: 45%;
}
.wdth_55 {
  width: 55%;
}

.portfolio_wdth_55 {
  width: 55%;
}

/* .wdth_card_55 {
  width: 55%;
} */

.ml_10 {
  margin-left: 10px;
}
.valut_header {
  margin-left: 10px;
  /* margin-top: 20px; */
}

.tvl_back {
  background: var(--block_background);
  color: var(--body_color);
}

.wdth_40 {
  width: 40%;
}

.wdth_40_first {
  width: 40%;
}

.wdth_30_first {
  width: 30%;
}

.wdth_30 {
  width: 30%;
}

.pddng_10 {
  padding: 10px;
}

.pddng_20 {
  padding: 20px;
}

.mrgn_left_10 {
  margin-left: 10px;
}

.last_section_back {
  background: #8861ec;
  color: white;
}

.outer_section_last {
  border: 1px solid #c4c4c4;
  padding: 15px 0px 15px 15px;
  margin: 10px;
}

.last_header_inner {
  color: white;
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 600;
}

.last_section_desc {
  width: 70%;
  margin-bottom: 30px;
  font-weight: 500;
}
.dspl_between {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.width_48 {
  width: 48%;
}

/* .dsp_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
} */

/* .dsp_cont_first_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

.dsp_prt_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.outer_section_first {
  border: 2px solid var(--border_color);
  padding: 0px 0px 0px 20px;
  margin: 10px;
}
/* .first_sec_heder1 {
  font-size: 24px;
  font-weight: 600;
} */

.first_sec_heder2 {
  color: #914de5;
  font-size: 26px;
  font-weight: 600;
}

.first_sec_dsp_intr {
  display: flex;
  justify-content: space-between;
  width: 70%;
}

.txt_clr_grn {
  font-weight: 600;
  color: #63a748;
}

.wdth_80 {
  width: 80%;
}

/* nav {
    position: sticky;
    top: 0;
  } */
nav ul {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  box-sizing: border-box;
  padding: 0px 0px;
  background: #fff;
  margin-bottom: 0;
}
nav ul li {
  list-style: none;
  /* margin-right: 20px; */
  font-size: 16px;
  cursor: pointer;
  /* font-weight: bold; */
}
nav ul li a {
  text-decoration: none;
  color: #000000;
  /* color: #1ac; */
}
.active {
  color: rgba(145, 77, 229, 1);
  padding-bottom: 13px;
  border-bottom-style: solid;
  border-bottom-width: 5px;
}
.nav_design {
  position: sticky;
  top: 0;
  background: var(--body_background);
  z-index: 2;
}

/* table{
    width: 100%;
  
  } */

.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;
}
/* table, th, td {
    border:1px solid black;
  } */
tr {
  background: rgba(40, 91, 221, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.02);
  /* border: 1px solid rgba(0, 0, 0, 0.02); */
}

thead {
  border: none;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0px;
}

.table > thead {
  height: 40px;
  line-height: 40px;
}
.brdr_blck {
  border: 1px solid var(--border_color);
}
.pdng_box {
  padding: 6px 20px;
}

.wdth_18 {
  width: 18px;
}

.transactionVenusWdth {
  width: 25px;
  margin-right: 5px;
}

.trnsCpyWdth {
  width: 15px;
  margin-left: 5px;
}

.small-div-1 {
  /* position: absolute;
    width: 113.68px;
    height: 121px;
    left: 736.8px;
    top: 133px;
    background: #914DE5;
    filter: blur(98.5px);
    transform: matrix(-1, 0, 0, 1, 0, 0); */
  position: absolute;
  width: 120px;
  height: 60px;
  left: 180.8px;
  top: 140px;
  background: #914de5;
  filter: blur(98.5px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.p-dialog .p-dialog-header {
  padding: 0.5rem 1.5rem;
}
.gif_wdth {
  width: 100px;
  margin-bottom: 35px;
}

.gif_success_wdth {
  width: 125px;
  margin-bottom: 25px;
}

.small-div-2 {
  /* position: absolute;
    width: 113.68px;
    height: 121px;
    left: 736.8px;
    top: 133px;
    background: #914DE5;
    filter: blur(98.5px);
    transform: matrix(-1, 0, 0, 1, 0, 0); */
  position: absolute;
  width: 120px;
  height: 60px;
  right: 180.8px;
  top: 140px;
  background: #914de5;
  filter: blur(98.5px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.p-tabview-title {
  margin-left: 20%;
}

.p-tabview .p-tabview-nav li {
  /* border: 1px solid; */
  width: 50%;
}

.txtAlgnRight {
  text-align: right;
}

/* @media (max-width: 992px) {
  .dllrImgwdthPort {
    
    height: 190px;
    margin-bottom: -2rem;
    display: block;
    margin-top: -47px;
  }
} */

/* @media (min-width: 768px) {
  .dllrImgwdthPort {
    width: 255px;
    height: 170px;
    margin-top: -32px; 
  }
} */

/* @media (min-width: 1200px) {
  .dllrImgwdthPort {
    display: none; 
  }
} */

.capitalize-words {
  text-transform: capitalize;
}

.sftyImgWdth {
  margin-left: 5px;
  margin-top: -5px;
}
.mrInf {
  color: rgba(145, 77, 229, 1);
  text-decoration: underline;
}

.wdth_28 {
  width: 28px;
}

.opt_67 {
  opacity: 67%;
}

.rvr_sty {
  opacity: 35%;
  margin-left: 26px;
  font-size: 14px;
}

.mrgn_18 {
  margin: 0px 8px;
}

.pdng_18 {
  padding-left: 16px !important;
}

.fnt_14 {
  font-size: 14px;
}

.p-menubar ul {
  margin-left: 20px;
}

.mr_38 {
  margin-right: 38px;
}
.mr_card_38 {
  margin-right: 38px;
}

.mrgn_5 {
  margin: 0px 5px;
}

.pdng_10 {
  padding-bottom: 10px;
}
.trsnaCpyImg {
  width: 14px;
  margin-left: 5px;
  margin-top: -3px;
}

.small-home-div-1 {
  position: absolute;
  width: 150px;
  height: 60px;
  left: 35%;
  top: 140px;
  background: #914de5;
  filter: blur(98.5px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
.small-home-div-3 {
  position: absolute;
  width: 150px;
  height: 60px;
  left: 10%;
  top: 56%;
  background: #914de5;
  filter: blur(98.5px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.small-home-div-4 {
  position: absolute;
  width: 150px;
  height: 60px;
  right: 38%;
  top: 56%;
  background: #914de5;
  filter: blur(98.5px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.mrgn_rght_neg {
  margin-right: -15px;
}

.wdth_150 {
  width: 150px;
}
.sectn_2_img {
  width: 240px;
  margin-top: -15px;
  margin-left: -50px;
  margin-right: -15px;
}
.pdng_btm_zero {
  padding-bottom: 0px;
}

.pdng_btm_18 {
  padding-bottom: 18px;
}

.prtfol_mrgn {
  margin-left: 20px;
  margin-bottom: 15px;
}

.tvl_mrgn {
  margin-left: 20px;
  margin-bottom: 12px;
}

.ml_25 {
  margin-left: 25px;
}

.wdth_60 {
  width: 60%;
}

.wdth_80 {
  width: 80%;
}

.view_btn_wdth {
  width: 100%;
  padding: 12px 10px 10px 10px;
  box-shadow: 5px 5px 0px var(--connect_btn_clr);
  transition: all ease 0.5s;
  min-height: 50px;
  font-weight: 600;
}

.view_btn_wdth:hover {
  background: #914de5;
  color: white;
}

.fundmanage_btn_wdth {
  width: 100%;
  /* padding: 12px 10px 10px 10px; */
  box-shadow: 5px 5px 0px var(--connect_btn_clr);
  transition: all ease 0.5s;
  /* min-height: 50px; */
  font-weight: 600;
}

.fundmanage_btn_wdth:hover {
  background: #914de5;
  color: white;
}

.connect_btn_wdth {
  /* width: 100%; */
  /* padding: 10px; */
  box-shadow: 5px 5px 0px var(--body_color);
  transition: all ease 0.5s;
}

.connect_btn_wdth:hover {
  background: #914de5;
  color: white;
}

/* .prgrs_txt {
  font-weight: 600;
  margin-top: -3px;
  margin-left: 11px;
} */
.header_warning {
  background: rgba(255, 120, 120, 1);
  color: white;
  text-align: center;
  padding: 10px;
}
.header_warning_font {
  font-weight: 600;
  font-size: 18px;
  font-family: RedHat;
}

/* .p-menubar {
  border: none;
  padding: 15px;
  background: var(--header_background);
} */

.p-menubar
  .p-menubar-root-list
  > .p-menuitem
  > .p-menuitem-link
  .p-menuitem-text {
  color: var(--header_text_color);
}

.bg_gray {
  background: var(--header_background);
}

.left_wrgn_alrt {
  width: 15px;
  margin-top: -2px;
  /* margin-left: 10px; */
}

.right_wrgn_alrt {
  width: 15px;
  margin-top: -2px;
  /* margin-right: 10px; */
}

.pddng_10_left {
  padding: 0px 10px;
}

.earlyacesBtn {
  min-width: 130px;
  background: white;
  /* width: 240px; */
  padding: 10px;
  box-shadow: 5px 5px 0px black;
  transition: all ease 0.5s;
  color: black;
  padding: 10px 28px;
}

.btn:focus {
  box-shadow: 5px 5px 0px black;
}

.earlyacesBtn:hover {
  background: #914de5;
  color: white;
}

.ml_8 {
  margin-right: 8px;
}
.mr_45 {
  margin-right: 45px;
}
.pos_sticky {
  position: sticky;
  top: 0;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #3d5af1 transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}
nav ul {
  background: var(--body_background);
  color: var(--body_color);
  justify-content: space-between;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* 
.custom-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
} */

.chain_pos {
  margin-top: -18px;
  margin-right: -15px;
}
.chain_pos_det {
  margin-top: -16px;
  margin-right: -15px;
}

.chain_pos_det_v2 {
  margin-top: -20px;
  margin-right: -15px;
}

.dspWrap {
  flex-wrap: wrap;
}
.wdth_80 {
  width: 80%;
}

.p-tabview-nav-container {
  margin: -15px -15px 40px -14px;
}

/* toggle button start */

.dark_mode {
  margin-top: -20px;
  margin-left: 10px;
}

.dark_mode_label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
    inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;
}
.dark_mode_label:after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.dark_mode_input {
  width: 0;
  height: 0;
  visibility: hidden;
}
.dark_mode_input:checked + .dark_mode_label {
  background: #242424;
}
.dark_mode_input:checked + .dark_mode_label:after {
  left: 62px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}
.dark_mode_label:active:after {
  width: 30px;
}

.dark_mode_label svg {
  position: absolute;
  width: 20px;
  top: 5px;
  z-index: 100;
}
.dark_mode_label svg.sun {
  left: 5px;
  fill: #fff;
  transition: 0.3s;
}
.dark_mode_label svg.moon {
  left: 40px;
  fill: #7e7e7e;
  transition: 0.3s;
}
.dark_mode_input:checked + .dark_mode_label svg.sun {
  fill: #7e7e7e;
}
.dark_mode_input:checked + .dark_mode_label svg.moon {
  fill: #fff;
}

.vault_header_font_size {
  font-size: 16px;
  font-weight: 600;
  /* font-family: RedHat; */
}

.ml_15 {
  margin-left: 15px;
}

.vault_sub_header_font_size {
  font-size: 18px;
  font-weight: 100;
  margin-top: 10px;
}

.ml_44 {
  margin-left: 44px;
}

.mr_12 {
  margin-right: 12px;
}

/* toggle button end */

.quantBullPadding {
  padding: 0px 0px 0px 15px;
}

.qunatCardPadding {
  padding: 15px 15px 0px 15px;
}

.wdth_275px {
  width: 275px;
}

/* .pddng_15 {
  padding: 15px;
} */

.crsr_pntr {
  cursor: pointer;
}

.wdth_50 {
  width: 50%;
}

.mr_20 {
  margin-right: 20px;
}
.clr_prpl {
  color: rgba(145, 77, 229, 1);
}

.mxBtn {
  height: 30px;
  font-size: 12px;
  margin-top: -3px;
  background: rgba(145, 77, 229, 1);
  border: rgba(145, 77, 229, 1);
  color: white;
}

.loeader_mrgn {
  margin-left: 20px;
  margin-right: 20px;
}

.mb_8 {
  margin-bottom: 8px;
}

.pos_dp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileDevice {
  display: none;
}

.wrdWrp {
  overflow-wrap: break-word;
  width: 350px;
}

.rocketImg {
  display: block;
}

.logoImg {
  display: block;
}

.homePort {
  width: 40%;
}
.homePort_30 {
  width: 30%;
}

.ovrFlow {
  overflow: auto;
}

.overflowWrap {
  overflow-wrap: anywhere;
}
.dialogWdth {
  width: 30vw;
}

.d_flex_frst {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* @media (min-width: 1200px) {
  .custom-container {
    max-width: 11400px;
  }
} */
/* 
@media (min-width: 1400px) {
  .custom-container {
    max-width: 1320px;
  }
} */

@media screen and (max-width: 640px) {
  .notMobileDevice {
    display: none;
  }
  .mobileDevice {
    display: block;
  }

  .dialogWdth {
    width: 80vw;
  }

  .card_wdth_50 {
    width: 100%;
  }
  .rocketImg {
    display: none;
  }
  .logoImg {
    display: none;
  }
  .homePort {
    width: 100%;
  }
  .homePort_30 {
    width: 100%;
  }
  .dsp_prt_cont {
    flex-direction: column;
  }
  .dsp_cont_first_section {
    flex-direction: column;
    padding: 20px;
  }
  .wdth_40_first {
    width: 100%;
  }
  .wdth_30_first {
    width: 100%;
  }
  .outer_section_first {
    padding: 0px;
  }
  .wdth_card_55 {
    width: 65%;
  }

  .mr_card_38 {
    margin-right: 0px;
  }

  .mbl_mrgn_10 {
    margin: 10px;
  }

  .ovrFlow {
    overflow: auto;
  }

  .OvervwWdth {
    width: 100%;
  }

  .OvervwWdth > * {
    width: 48%;
  }

  .portfolio_wdth_55 {
    width: 100%;
  }

  .mob_clm_rvs {
    flex-direction: column-reverse;
  }

  .btn-riv-secondary {
    min-width: 50px;
    font-size: 12px;
  }

  .mr_mob_20 {
    margin-right: 4px;
  }

  /* .bckgrnd_img_card {
    background-image: url("./assets/images/stableFusionxMob.png");
  } */

  nav ul li {
    margin-right: 20px;
  }
}

@media screen and (max-width: 350px) {
  .chain_pos_det_v2 {
    margin-top: -52px;
    margin-right: -15px;
  }
}
/* ADDED */
.width_40 {
  width: 20rem;
}
.mr_10 {
  margin-right: 4rem;
}
.width_20 {
  width: 15rem;
}
.transationCont {
  max-width: fit-content;
}
.green_txt {
  color: rgb(95, 236, 95);
}

/* Apply margin-left: 30% only on large screens */
@media screen and (min-width: 1200px) {
  .transationCont {
    margin-left: 25%;
  }
}
.w40 {
  width: 21rem;
  height: 10rem;
}
.lgt_purple {
  background-color: #f9f5ff !important;
}

/* Adjust width for mobile screens */
@media screen and (max-width: 767px) {
  .w40 {
    width: 31rem;
  }
}

.mLMed20 {
  margin-right: -20rem;
}
@media screen and (min-width: 1200px) {
  .mLMed20 {
    margin-right: -68rem;
  }
}
@media screen and (max-width: 767px) {
  .mLMed20 {
    margin-left: -20rem;
  }
}

.flexD {
  display: flexbox;
  border: 1px solid gray;
  width: fit-content;
}
.flexTH {
  display: flex;
  align-items: center;
  gap: 10rem;
}
.nodata {
  width: 120%;
  background-color: #000;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .mLMed {
    margin-left: 2rem;
  }
}
/* CSS for maintaining original style when no filtered data */
.table-empty tbody {
  display: block; /* Ensure the table body is a block element */
  text-align: center; /* Center the text within the block */
}

.table-empty tr {
  display: table-row; /* Reset display property for tr elements */
}

.table-empty td {
  display: table-cell; /* Reset display property for td elements */
}

/* Adjust gap for large screens */
@media screen and (min-width: 1200px) {
  .flexTH {
    gap: 5rem;
  }
}
@media screen and (max-width: 600px) {
  .flexTH {
    gap: 3rem; /* Adjust the gap for small screens */
  }
}
.flexopt {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.bgGrad {
  background: linear-gradient(
    to top right,
    rgba(203, 173, 231, 0.3),
    transparent
  );
}
.dropdownCont {
  max-width: fit-content;
}
.flexDo {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
}

.Doughnutflex-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem; /* Set the desired gap value */
}

@media (max-width: 768px) {
  .Doughnutflex-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.doughnut {
  padding: 1rem;
  margin: 1rem 1rem 1rem 0.8rem;
}
@media only screen and (max-width: 600px) {
  .doughnut {
    width: 94%;
  }
}

@media screen and (max-width: 767px) {
  .dough_width {
    margin-top: 1rem;
    width: 130px; /* Adjust the width as needed */
    height: 200px;
  }
}

@media screen and (min-width: 768px) {
  .dough_width {
    width: 250px; /* Adjust the width as needed */
    height: 250px; /* Adjust the height as needed */
  }
}

.min_1r {
  min-width: 1.5rem;
}
.width_4 {
  width: 4rem;
}
/* .ml-1 {
  margin-left: 2rem;
} */

.flex {
  display: flex;
  align-items: center;
  gap: 5rem;
}
.flexB {
  display: flex;
  align-items: center;
}
.flexT {
  display: flex;
  gap: 13px;
}
.flexCont {
  column-count: 2;
  /* column-gap: 40px; */
  column-gap: 10px;
}
.skel {
  display: flex;
  /* padding-left: 20px; */
  gap: 20px;
  padding-right: 20px;
}
.mb-10 {
  margin-top: 100px;
}
.hightdisCon:hover {
  transform: scale(0.99);
}
.hightCon:hover {
  transform: scale(0.99);
}
.emp {
  margin-right: -10rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}
.join {
  font-weight: 600;
  white-space: nowrap;
  padding-top: 10px;
  color: var(--disc_color);
  font-size: 1.2rem;
  padding-left: 52px;
}

@media (max-width: 768px) {
  .join {
    font-size: 1.1rem;
    padding-left: 52px;
  }
}
.imd {
  position: absolute;
  margin-bottom: -0.4rem;
  margin-left: -2.8rem;
}

.zin {
  z-index: 20;
}

.table {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;
}
.table {
  .border {
    flex: 1;
    min-height: 481px;
    max-width: 574px;
    border: 2px solid #000;
    flex-direction: column;
  }
}
.table-container {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  /* laks */
  background-color: var(--rewards);
}

.table-container th,
.table-container td {
  padding: 28px;
  text-align: left;
}

.p-sec {
  padding: 0.8rem;
  display: flex;
  flex-wrap: unset;
}
@media (min-width: 768px) {
  .p-sec {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
}

/* Grid layout for small screens */
@media (max-width: 767px) {
  .p-sec {
    display: grid;
    grid-template-rows: repeat(2);
  }
}
.outer_section_first_xRiv {
  border: 2px solid var(--border_color);
  padding: 0px 0px 0px 20px;
  margin: 10px;
  background: linear-gradient(
    to top right,
    rgba(138, 43, 226, 0.3),
    transparent,
    rgba(145, 208, 237, 0.7)
  );
}
.discB1 {
  border-radius: 0;
  min-width: 130px;
  box-shadow: 5px 5px 0px var(--connect_btn_clr);
  border: 1px solid black;
  color: var(--seconday_color);
}
.discB1:hover {
  color: var(--seconday_color);
}

.mb-8 {
  margin-top: 68px;
}
@media (max-width: 728px) {
  .hightCon {
    max-width: 100px;
    /* margin-top: 20px; */
    margin-left: -13.5rem;
  }
}
@media (max-width: 728px) {
  .hightdisCon {
    max-width: 100px;
    margin-top: 24px;
    margin-left: -13rem;
  }
}
.clock-container {
  margin-bottom: 1rem;
  margin-top: -0.4rem;
  display: flex;
  height: fit-content;
  padding-bottom: 4rem;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border_color);
  border-radius: 5px;
}

.heading {
  font-size: 16px;
  font-weight: 600;
}
.headingDo {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: flex-start;
  font-size: 20px;
  font-family: var(--font-red-hat-display);
  font-weight: 500;
}

.centered-content {
  display: flex;
  margin-top: 8rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.centered-content1 {
  display: flex;
  width: 100%;
  margin-top: 8rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.clock-image {
  width: 60px;
  height: 60px;
  /* position: fixed; */
}

.Clocktext {
  margin-top: 10px;
  margin-top: 10px;
  font-size: 16px;

  text-align: center;
  font-weight: 500;
  line-height: normal;
}
.text-green {
  color: rgb(75, 214, 75);
}
.text-purple {
  color: rgb(154, 99, 230);
}
.bg-col-green-100 {
  background-color: #d1fae5;
  width: fit-content;
}

.rounded-md {
  border-radius: 5rem; /* Adjust the border-radius value as needed */
  padding: 12px; /* Replace with your desired green color */
}

.his_head {
  margin-top: -1.4rem;
}

.separator-line {
  margin-top: -40px;
  border-top: 1px solid var(--border_color); /* Adjust the style and color as needed */
  width: 100%;
}
.table-separator {
  border-bottom: 1px solid var(--border_color); /* Adjust the style and color as needed */
}
.font_light {
  font-weight: 200;
}

.hist_empty {
  /* background-color: #000; */
  margin-top: 2rem;
}
.hist_emptyNOC {
  margin-top: 1.8rem;
}
.his_nocon {
  /* background-color: pink; */
  margin-top: -2rem;
}

.frame {
  /* background-color: #000; */
  align-items: center;
  justify-content: flex-start;
}
.bgwhite {
  background-color: var(--body_background);
}
.textwhite {
  color: var(--body_background);
}
.flexnone {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.outer_section_first_portfolio {
  border: 2px solid var(--border_color);
  display: flex;
  width: min-content;
  padding: 0px 0px 0px 20px;
  margin: 10px;
  background: linear-gradient(to top left, rgba(255, 182, 193, 0.3));
}

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");

.frame-child {
  position: relative;
  background-color: var(--color-salmon);
  width: 80rem;
  height: 4.63rem;
  display: none;
  max-width: 100%;
  z-index: 0;
}
.alert-triangle-icon {
  position: relative;
  width: 1.31rem;
  height: 1.31rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.mvp-testing-risk {
  position: relative;
  white-space: nowrap;
  z-index: 1;
}
.alert-triangle-icon1 {
  position: relative;
  width: 1.31rem;
  height: 1.31rem;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.mvp-testing-risk-notice-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-22xl);
}
.alert-triangle-parent,
.frame-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.alert-triangle-parent {
  align-items: flex-end;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.frame-container {
  align-items: flex-start;
  padding: 0 var(--padding-12xs) 0 var(--padding-8xl);
  text-align: left;
  font-size: var(--font-size-lg);
  font-family: var(--font-red-hat-display);
}
.more-info,
.rivera-contracts-are {
  display: inline-block;
  width: 65.06rem;
  white-space: nowrap;
}
.rivera-contracts-are {
  position: relative;
  max-width: 100%;
  z-index: 1;
}
.more-info {
  position: absolute;
  margin: 0 !important;
  right: -17.11rem;
  bottom: 1.07rem;
  text-decoration: underline;
  color: #9d0f0f;
  z-index: 2;
}
.rectangle-parent {
  background-color: var(--color-salmon);
  width: 80rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-4xs) 2.94rem var(--padding-mid) var(--padding-xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-6xs);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--body-md-10);
}
.frame-wrapper,
.macbook-air-31-inner {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.frame-wrapper {
  width: 97.13rem;
  flex-direction: column;
  justify-content: center;
  max-width: 121%;
}
.macbook-air-31-inner {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  min-height: 4.81rem;
  max-width: 100%;
}
.color-rectangle-icon {
  position: relative;
  width: 11.25rem;
  height: 3rem;
  object-fit: cover;
}
.frame-inner,
.frame-item {
  position: relative;
  border-radius: 28.86px;
  background-color: var(--color-white);
  box-sizing: border-box;
  height: 1.91rem;
}
.frame-item {
  border: 0.8px solid var(--color-gray-200);
  width: 9.28rem;
  display: none;
}
.frame-inner {
  flex: 1;
  border: 0.8px solid var(--color-black);
  z-index: 1;
}
.investor {
  position: relative;
  font-size: 0.64rem;
  font-family: var(--font-poppins);
  color: var(--color-black);
  text-align: left;
  flex-shrink: 0;
  z-index: 2;
  margin-left: -3.87rem;
}
.rectangle-container {
  cursor: pointer;
  border: 0;
  padding: 0 var(--padding-4xl) 0 0;
  background-color: transparent;
  width: 6.56rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}
.rectangle-group {
  align-self: stretch;
  border-radius: 28.86px;
  background-color: var(--color-white);
  border: 0.8px solid var(--color-gray-200);
  flex-direction: row;
  align-items: center;
}
.color-rectangle-parent,
.frame-div,
.rectangle-group {
  display: flex;
  justify-content: flex-start;
}
.frame-div {
  width: 9.31rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 var(--padding-9xs);
  box-sizing: border-box;
}
.color-rectangle-parent {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-19xl);
  max-width: 100%;
}
.rectangle-div,
.rectangle-icon {
  position: absolute;
  margin: 0 !important;
}
.rectangle-div {
  height: 100%;
  width: 100%;
  top: -0.01rem;
  right: 0.01rem;
  bottom: 0.01rem;
  left: -0.01rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
}
.rectangle-icon {
  right: -4.74rem;
  bottom: -1.49rem;
  width: 11.5rem;
  height: 3.56rem;
}
.x9208383 {
  position: relative;
  z-index: 2;
}
.vector-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}
.chevron-down-icon {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 1.5rem;
  z-index: 2;
}
.rectangle-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-mid) var(--padding-sm) var(--padding-base)
    var(--padding-mid);
  position: relative;
  gap: var(--gap-10xl);
}
.frame-parent,
.macbook-air-31-child {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}
.frame-parent {
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-xl);
}
.macbook-air-31-child {
  width: 75.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 2.13rem 0 var(--padding-xl);
  min-height: 4.19rem;
  flex-shrink: 0;
}
.vector-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.macbook-air-31-inner1 {
  align-self: stretch;
  height: 1.19rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}
.div {
  position: relative;
  font-size: 0.76rem;
  font-weight: 500;
  font-family: var(--body-md-10);
  color: var(--color-white);
  display: none;
}
.mask-group-icon,
.vault-icon {
  position: absolute;
  margin: 0 !important;
}
.vault-icon {
  top: -0.15rem;
  left: calc(50% + 581.3px);
  background-color: var(--color-white);
  border: 2px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  width: 16.05rem;
  height: 72.75rem;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.mask-group-icon {
  height: 100%;
  width: 100%;
  top: -0.01rem;
  right: 0.08rem;
  bottom: 0.07rem;
  left: -0.01rem;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}
.portfolio {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  opacity: 0.69;
  z-index: 2;
}
.span {
  font-size: 1.77rem;
}
.all-polygon {
  position: relative;
  font-weight: 800;
  color: var(--color-darkorchid-100);
  white-space: nowrap;
  z-index: 2;
  font-size: 2.56rem;
}
.portfolio-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.overall-returns {
  position: relative;
  opacity: 0.68;
  z-index: 2;
}
.span1 {
  font-size: 0.84rem;
}
.b {
  position: relative;
  color: var(--color-olivedrab);
  white-space: nowrap;
  z-index: 2;
  font-size: var(--font-size-mid-8);
}
.background-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-smi);
}
.average-apy,
.b1 {
  position: relative;
  z-index: 2;
}
.average-apy {
  opacity: 0.68;
}
.b1 {
  font-size: var(--font-size-mid-8);
  color: var(--color-olivedrab);
}
.background-color1 {
  flex-direction: column;
  gap: var(--gap-smi);
}
.background-color1,
.frame-parent2,
.rectangle-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangle-frame {
  flex-direction: row;
  gap: var(--gap-12xl);
  font-size: var(--font-size-base);
  font-family: var(--body-md-10);
}
.frame-parent2 {
  flex-direction: column;
  gap: var(--gap-13xl);
  min-width: 15.44rem;
}
.h {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 2;
}
.balance-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-10xs) 0 0;
}
.transaction-history-frame-child {
  position: relative;
  border-radius: var(--br-6xs);
  background-color: var(--color-darkorchid-100);
  width: 2.16rem;
  height: 1.23rem;
  display: none;
}
.w {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 3;
}
.transaction-history-frame {
  border-radius: var(--br-6xs);
  background-color: var(--color-darkorchid-100);
  height: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-10xs) var(--padding-xs) var(--padding-11xs)
    var(--padding-4xs);
  box-sizing: border-box;
  z-index: 2;
  color: var(--color-white);
}
.m {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 2;
}
.balance-icon1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-sm) 0 0;
}
.m1 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 2;
}
.balance-icon2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs) 0 0;
}
.m2 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 2;
}
.balance-icon3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-xs) 0 0;
}
.y {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  z-index: 2;
}
.balance-frame,
.date-node {
  display: flex;
  flex-direction: row;
}
.balance-frame {
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.date-node {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 var(--padding-3xl);
  color: var(--color-black);
}
.frame-group1 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 2;
}
.frame-child1 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  min-width: 21.75rem;
  z-index: 3;
}
.frame-group-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-sm);
  min-height: 1.13rem;
  max-width: 100%;
}
.div1 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 2;
}
.frame-child2 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  min-width: 21.75rem;
  z-index: 3;
}
.parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-sm);
  min-height: 1.13rem;
  max-width: 100%;
}
.div2 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 2;
}
.frame-child3,
.text-nodes-label {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.frame-child3 {
  flex: 1;
  position: relative;
  min-width: 21.75rem;
  z-index: 3;
}
.text-nodes-label {
  top: -0.02rem;
  right: 0.04rem;
  left: -0.02rem;
  z-index: 4;
}
.date-frame-icon,
.text-frame-group,
.text-nodes-label {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
}
.date-frame-icon {
  top: -0.02rem;
  right: 0.04rem;
  left: -0.02rem;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 5;
}
.text-frame-group {
  top: 0;
  right: 0;
  left: 0;
}
.text-frame-group-wrapper {
  position: absolute;
  margin: 0 !important;
  top: -4.69rem;
  right: 1.06rem;
  width: 31.06rem;
  height: 7.63rem;
}
.div3,
.group {
  position: relative;
}
.group {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--padding-12xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-sm);
  min-height: 1.13rem;
  max-width: 100%;
}
.div3 {
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  white-space: nowrap;
  z-index: 2;
}
.border2 {
  border: 2px solid var(--border_color);
}
.border2Table {
  margin-left: -10rem;
  border: 2px solid var(--border_color);
}
.border2Port {
  border: 2px solid var(--border_color);
  margin-left: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}
.polygon-container-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  min-width: 21.75rem;
  z-index: 3;
}
.container,
.frame-wrapper1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}
.container {
  flex: 1;
  align-items: center;
  padding: 0 var(--padding-12xs) 0 0;
  gap: var(--gap-sm);
}
.frame-wrapper1 {
  align-self: stretch;
  align-items: flex-start;
  padding: 0 var(--padding-9xs) 0 0;
}
.dec-12,
.dec-13,
.dec-14,
.dec-15,
.dec-16 {
  position: relative;
  letter-spacing: -0.5px;
  line-height: 0.88rem;
  font-weight: 500;
  display: inline-block;
  width: 2.51rem;
  flex-shrink: 0;
  z-index: 2;
}
.dec-13,
.dec-14,
.dec-15,
.dec-16 {
  width: 2.59rem;
}
.dec-15,
.dec-16 {
  width: 2.51rem;
}
.vault-icon1 {
  width: 33.19rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--padding-9xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
}
.date-node-parent,
.frame-parent3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}
.frame-parent3 {
  align-self: stretch;
  align-items: flex-end;
  gap: var(--gap-5xs);
}
.gap-6 {
  gap: var(--gap-mid);
}

.date-node-parent {
  width: 37rem;
  align-items: flex-start;
  gap: var(--gap-3xl);
  min-width: 37rem;
  font-size: var(--body-md-10-size);
  color: var(--color-gray-300);
  font-family: var(--body-md-10);
}
.frame-child4,
.vault-label {
  position: relative;
}
.custom-row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5rem;
  max-width: 100%;
}
.vault-label {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem 3.13rem 3rem;
  border: 2px solid var(--border_color);
  background: -webkit-linear-gradient(135deg, var(--graph0), var(--grapgh));
  gap: 5rem;
  max-width: 100%;
  margin: 1rem;
}
@media only screen and (max-width: 600px) {
  .vault-label {
    width: 95%;
    /* background-color: red !important; */
    flex-direction: row;
    border: 2px solid var(--border_color);
    padding: 3rem 0rem 2rem 10px;
    gap: 0rem;
    margin: 0.5rem 0.5rem 0.5rem 0.7rem !important;
  }
}

.table-label {
  align-self: stretch;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem 1.5rem 0.5rem;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .table-label {
    gap: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: -1rem;
  }
}
.imDropwdth {
  /* background-color: red !important; */
  width: 100px;
}

.frame-child4 {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  width: 19.05rem;
  height: 35.43rem;
  transform: rotate(90deg);
  display: none;
}
.your-assets {
  margin: 0;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  opacity: 0.69;
  z-index: 2;
}

.grid-flow-rows {
  grid-auto-flow: row;
}

.h-20 {
  height: 20px;
}
.exp-table::-webkit-scrollbar-thumb {
  display: none; /* Hide scrollbar handle */
}
.header_of_table_exp {
  background: var(--exp-tableHead) !important;
  color: var(--exp-tabletext) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}
.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}
.grid-3 {
  display: grid;
  grid-template-columns: auto 1fr 1fr; /* Define column widths */
  /* gap: 10px; Optional: Gap between grid items */
}
.gap-x-2 > * + * {
  margin-left: 0.5rem;
}

.gap-x-4 > * + * {
  margin-left: 4rem;
}
p {
  margin: 0;
}
.red200 {
  background-color: pink !important;
  border: " 2px solid red !important";
}

.lineChart {
  width: 50%;
  height: 20%;
}

/* Default styles */
.table-containe1 {
  background-color: var(--rewards);
}

/* Styles for small screens */
@media only screen and (max-width: 600px) {
  .table-containe1 {
    margin-left: 0.3rem !important;
    margin-right: 20px !important;
  }

  .table-containe1 th,
  .table-containe1 td {
    padding: 6px;
    text-align: left;
  }
}

/* Styles for medium screens */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .table-containe1 {
    margin-left: 1rem;
    margin-top: 30px;
    width: 95%;
  }

  .table-containe1 th,
  .table-containe1 td {
    padding: 28px;
    text-align: left;
  }
}

/* Styles for large screens */
@media only screen and (min-width: 1025px) {
  .table-containe1 {
    margin-left: 2rem;
    margin-top: 30px;
    width: 95%;
  }

  .table-containe1 th,
  .table-containe1 td {
    padding: 28px;
    text-align: left;
  }
}

.fontbold {
  font-weight: 600;
}
.droptable {
  background-color: var(--body_background);
  color: var(--body_color);
}
.exp {
  background-color: var(--exp);
}
/* Responsive styles */
.responsive-table-container {
  margin-left: 20rem;
  border: " 1px solid var(--border_color)"; /* Default margin for large screens */
}

/* Media query for medium screens */
@media only screen and (max-width: 1024px) {
  .responsive-table-container {
    margin-left: 0; /* No left margin for medium screens */
  }
}

@media only screen and (max-width: 768px) {
  .responsive-table-container {
    margin-left: 0; /* No left margin for small screens */
  }
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
}
.skeleton-bar {
  height: 20px; /* Adjust the height of each skeleton bar */
  width: 100%;
  background-color: #e0e0e0; /* Adjust the background color of the skeleton bars */
  margin-bottom: 8px; /* Adjust the margin between skeleton bars */
  border-radius: 4px; /* Optional: Add rounded corners to skeleton bars */
}
@keyframes skeleton-animation {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}
.skeleton-bar {
  animation: skeleton-animation 1.5s infinite;
}

/* Doughnut Loader */
.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; /* Adjust the height of the skeleton loader container */
}

.skeleton-circle {
  height: 15rem;
  width: 15rem;
  border: 40px solid #e0e0e0;
  border-radius: 50%;
  margin-right: 5.5rem;
  margin-left: 3.5rem;
  margin-top: 1.5rem;
}

.mt-1 {
  margin-top: 40px;
}

@keyframes skeleton-animation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.skeleton-circle {
  animation: skeleton-animation 1.5s infinite;
}

.mx {
  margin-left: 1rem;
  margin-right: 1rem;
}

.ml-20 {
  margin-left: 20rem;
}
.port_flex {
  display: flex;
  align-items: center;
  gap: -10px;
}

.small_circle {
  height: 20px;
  width: 20px;
  border-radius: 100%;
}
/* For large screens (wider than 992px), show the class */
@media (min-width: 993px) {
  .grapghwgdt {
    display: block;
  }
}

/* For medium and small screens, hide the class */
@media (max-width: 992px) {
  .grapghwgdt {
    display: none;
  }
}
.portfolio_font_size {
  font-size: 28px !important;
  font-family: var(--font-red-hat-display);
}
.w-1 {
  height: 12px;
}
.w_30 {
  width: 3rem !important;
}
.w_20 {
  width: 2rem !important;
}
@media screen and (max-width: 600px) {
  .w_30 {
    width: 2rem !important;
  }
}
@media screen and (min-width: 768px) {
  .values {
    width: 23rem;
  }
}
@media screen and (max-width: 767px) {
  .values {
    /* width: 12.3rem !important; */
    margin-left: -1.3rem;
    margin-top: -2.2rem !important;
  }
}
.table-container-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
  margin: 0.5rem;
  max-width: 100%; /* Ensure the container takes up the available width */
  min-height: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
@media screen and (max-width: 767px) {
  .border2Mob {
    border: 2px solid var(--border_color);
  }
}
.dropDown {
  border-radius: 0;
  background-color: var(--body_background);
}

.head-container {
  padding: 30px 20px 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--datatable-row-background);
  color: var(--datatable-row-color);
  transition: box-shadow 0.2s;
}

/* Portfolio clickable*/
.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
}
[data-theme="dark"] .clickable-row:hover {
  background-color: #444242 !important;
}
/* .clickable-row:hover {
  background-color: #444242 !important; 
} */
